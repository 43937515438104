@import "constants/constants.scss";

.about {
    min-height: calc(100vh - 64px);
    background-color: $color_main;
    padding-bottom: 40px;
    .about_title {
        @include title;
    }
    .about_block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: calc(100vh - 250px);
        @media (max-width: 1200px) {
            display: flex;
            flex-direction: column;
            height: auto;
        }
        .about_img {
            max-width: 90%;
            box-shadow: 8px 8px 10px 2px rgba(220, 150, 90, 0.2);
        }
        .about_desc {
            padding: 15px;
            .about_name {
                margin: 10px 0;
                color: $color_antiqueWhite;
                font-size: 2.5rem;
                @media (max-width: 600px) {
                    font-size: 2.2rem;
                }
            }
            .about_position {
                margin: 10px 0;
                color: $color_orange;
                font-size: 2rem;
                margin-bottom: 30px;
                @media (max-width: 600px) {
                    font-size: 1.5rem;
                }
            }
            .about_text {
                color: $color_lightGrey; 
                max-width: 400px;
                margin-bottom: 30px;
            }
            .about_button {
                margin: 20px 0 10px;
                background-color: $color_antiqueWhite;
                width: 100%;
                height: 50px;
                font-size: 1.2rem;
                text-transform: capitalize;
                @media (min-width: 600px) {
                    width: 400px;
                }
            }
            .about_button:hover {
                background-color: $color_grey;
                transition: 0.4s;
                border: 1px solid $color_antiqueWhite;
                color: $color_antiqueWhite;
            }
            a:link {
                text-decoration: none;
            }
        }
    }
}
