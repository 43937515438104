@import "constants/constants.scss";

.footer {
    background-color: $color_dark;
    .footer__social {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 0 15px 0;
        & svg {
            color: $color_orange;
            font-size: 50px;
            margin: 0 35px;
            @media (max-width: 600px) {
                width: 45px;
                margin: 0 25px;
            }
        }
        & svg:hover {
            transform: translateY(-3px);
        }
    }
    .footer__right {
        color: $color_antiqueWhite;
        text-align: center;
        padding-bottom: 25px;
        @media (max-width: 600px) {
            font-size: 0.8rem;
        }
    }
}
