@import "constants/constants.scss";

.home {
    min-height: 100vh;
    color: $color_antiqueWhite;
    background-color: $color_dark;
    display: flex;
    align-items: center;
    .container {
        @media (max-width: 1200px) {
            padding: 0 48px;
        }
        @media (max-width: 600px) {
            padding: 20px;
        }
        div:first-child {
            margin: 0 0 50px 0;
        }
        div:nth-child(3) {
            color: $color_orange;
        }
        div {
            font-size: 80px;
            font-family: Nunito;
            margin: 0;
            @media (max-width: 1200px) {
                font-size: 60px;
            }
            @media (max-width: 992px) {
                font-size: 50px;
            }
            @media (max-width: 768px) {
                font-size: 40px;
                margin: 20px 0;
            }
            @media (max-width: 600px) {
                font-size: 32px;
                margin: 20px 0;
                font-weight: 600;
            }
        }
        .button {
            font-size: 25px;
            display: inline;
            padding: 15px 25px;
            border: 1px solid $color_orange;
            border-radius: 5px;
            cursor: pointer;
            @media (max-width: 992px) {
                padding: 10px 15px;
            }
            @media (max-width: 768px) {
                padding: 8px 12px;
                font-size: 20px;
            }
        }
        .button a {
            color: $color_orange;
        }
        .button:hover {
            border: 1px solid $color_antiqueWhite;
            transition: 0.5s;
        }
        .button:hover a {
            color: $color_antiqueWhite;
            transition: 0.5s;
        }
    }
}
