@import 'constants/constants.scss';

.projects {
    min-height: calc(100vh - 64px);
    background-color: $color_dark;
    .title {
        @include title;
        padding-bottom: 25px;
    }
    .cardsBox {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding-bottom: 15px;
        .cards {
            margin: 15px;
        }
    }
}