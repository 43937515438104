@import "constants/constants.scss";

.navBar {
    display: flex;
    .logo {
        display: block;
        cursor: pointer;
        margin-top: 8px;
    }
    .logo:hover {
        transform: scale(1.2);
        transition: .5s;
    }
    .burger {
        margin-left: auto;
        color: #fff;
        @media (min-width: 900px) {
            display: none;
        }
        svg {
            font-size: 2rem;
        }
    }
    .itemBox {
        margin-left: auto;
        display: flex;
        @media (max-width: 899px) {
            display: none;
        }
        .link {
            cursor: pointer;
            padding: 0 16px;
            color: #fff;
        }
        .activeLink {
            color: $color_orange;
        }
        .lang {
            cursor: pointer;
            margin-left: 16px;
            padding: 0 8px;
            color: $color_lightGrey;
            border: 1px solid $color_lightGrey;
            border-radius: 5px;
        }
    } 
}
.drawer {
    display: none;
    @media (max-width: 899px) {
        display: block;
    }
    .drawerBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: $color_main;
        .drawerLogo {
            margin: 18px 0 10px;
        }
        .divider {
            background-color: #fff;
            width: 50%;
            margin: 0 auto;
            margin-bottom: 12px;
        }
        .drawerItem {
            display: block;
            padding: 5px 20px;
            color: #fff;
            font-size: 1.3rem;
            font-family: Nunito;
        }
        .drawerLang {
            cursor: pointer;
            font-family: Nunito;
            text-align: center;
            width: 50px;
            margin: 15px 0 30px;
            padding: 0 8px;
            color: $color_lightGrey;
            border: 1px solid $color_lightGrey;
            border-radius: 5px;
        }
    }
}
