@import 'constants/constants.scss';

.contacts {
    min-height: calc(100vh - 64px);    
    background-color: $color_grey;    
    .contacts_title {
        @include title;
    }
    .contacts_block {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: calc(100vh - 250px);
        @media (max-width: 1200px), (max-height: 768px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: auto;
        }
        .contacts_box {
            display: flex;
            align-items: center;
            margin: 50px 0;            
            & svg {
                color: $color_orange;
                font-size: 2.5rem;
            }
            @media (max-width: 350px) {
                flex-direction: column;
                & svg {
                    margin-bottom: 10px;
                }
            }
            .contacts_text {
                margin-left: 15px;
                color: $color_antiqueWhite;
                font-size: 1.2rem;
                & a {
                    margin-left: 10px;
                    color: #808080;
                    font-size: 1rem;
                    cursor: pointer;
                }
            }
        }
    }
}