@import "constants/constants.scss";

.skills {
    min-height: calc(100vh - 64px);
    padding-bottom: 20px;
    background-color: $color_grey;
    .skills_container {
        display: flex;
        justify-content: space-between;
        @media (max-width: 1200px) {
            flex-direction: column;
            align-items: center;
        }
        .study_block {
            color: $color_antiqueWhite;
            padding: 0 10px;
            height: 500px;
            @media (min-width: 1200px) {
                min-width: 450px;
            }
            @media (max-width: 1200px) {
                height: auto;
            }
            .study_title {
                @include title;
                text-align: left;
                padding-bottom: 25px;
            }
            .hobby_title {
                @include title;
                text-align: left;
                padding-top: 0;
            }
            .study_textBox {
                margin-bottom: 30px;
                p {
                    color: $color_lightGrey;
                }
                .study_subtitle {
                    color: $color_antiqueWhite;
                    font-size: 1.2rem;
                    margin: 15px 0;
                }
                .study_cert:hover {
                    color: $color_antiqueWhite;
                }
            }
            .hobby_textBox {
                color: $color_lightGrey;
                max-width: 500px;
                p {
                    padding-bottom: 10px;
                }
            }
        }
        .skills_block {
            display: flex;
            flex-direction: column;
            align-items: center;
            .skills_title {
                @include title;
                width: 650px;
                text-align: right;
                padding-bottom: 25px;
                @media (max-width: 1200px) {
                    width: 480px;
                }
                @media (max-width: 600px) {
                    width: 310px;
                }
                @media (max-width: 320px) {
                    width: auto;
                }
            }
            .skills_box {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                @media (max-width: 1200px) {
                    max-width: 600px;
                }
                @media (max-width: 600px) {
                    max-width: 400px;
                }
            }
        }
    }
}
