@import "constants/constants.scss";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $color_main;
    width: 500px;
    min-height: 500px;
    position: relative;
    box-shadow: 0 0 8px 10px rgba(19, 20, 22, 0.502);
    padding: 25px 0;
    @media (max-width: 996px) {
        margin: 0 auto;
    }
    @media (max-width: 600px) {
        width: 90%;
        margin: 0 auto;
    }
    .title {
        color: $color_antiqueWhite;
        font-size: 1.5rem;
    }
    .form {
        font-family: "Nunito", sans-serif;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 15px;
        position: relative;
        .field {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .input {
            background-color: $color_grey;
            color: #fff;
            margin: 10px;
            padding: 8px;
        }
        & p {
            color: #ff0000;
            font-size: .9rem;
        }
        .input:focus {
            color: #fff;
            border: 1px solid #fff;
        }
        .textarea > div {
            color: #fff;
            background-color: $color_grey;
            margin: 10px;
            padding: 10px;
            min-height: 150px;
            display: flex;
            flex-direction: column;
        }
        .textarea:focus {
            color: #fff;
        }
        .submit {
            background-color: $color_grey;
            color: #fff;
            margin: 10px;
            padding: 12px;
        }
    }
}
.container::before,
.container::after {
    position: absolute;
    content: "";
    transition: all 0.4s;
    width: 10%;
    height: 15%;
}

.container::before {
    border-bottom: 2px solid #dc965a;
    border-left: 2px solid #dc965a;
    left: 0;
    bottom: 0;
}

.container::after {
    border-top: 2px solid #dc965a;
    border-right: 2px solid #dc965a;
    top: 0;
    right: 0;
}
