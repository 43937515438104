$color_grey: #1c1d24;
$color_main: #131416;
$color_dark: #0e0f10;

$color_antiqueWhite: #faebd7;
$color_orange: #dc965a;
$color_lightGrey: #808080;

@mixin title {
    text-align: center;
    padding: 40px 0;
    font-size: 2rem;
    font-weight: 600;
    color: $color_antiqueWhite;
}
