@import "constants/constants.scss";

.card {
    background-color: $color_main;
    color: $color_antiqueWhite;
    padding: 20px;
    position: relative;
    .title {
        font-size: 1.25rem;
        margin-bottom: 10px;
        max-width: 350px;
    }
    .subtitle {
        margin-bottom: 20px;
        color: $color_lightGrey;
        max-width: 350px;
    }
    img {
        max-width: 100%;
    }
    .button {
        text-align: center;
        margin-top: 20px;
    }
    .button button {
        color: $color_antiqueWhite;
        z-index: 2;
    }
    button:hover {
        color: $color_orange;
        transition: 0.4s;
    }
}

.card:before,
.card:after {
    position: absolute;
    content: "";
    transition: all 0.4s;
    width: 10%;
    height: 15%;
}
.card:before {
    border-bottom: 2px solid $color_orange;
    border-left: 2px solid $color_orange;
    left: 0;
    bottom: 0;
}
.card:after {
    border-top: 2px solid $color_orange;
    border-right: 2px solid $color_orange;
    top: 0;
    right: 0;
}
.card:hover:before,
.card:hover:after {
    width: 100%;
    height: 100%;
}
