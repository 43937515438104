@import "constants/constants.scss";

.swiper-pagination-bullet {
    background: $color_lightGrey;
}

.swiper-pagination-bullet-active {
    background: $color_orange;
}

.swiper-button-next,
.swiper-button-prev {
    margin-top: -40px;
    color: $color_orange;
}

.swiper {
    padding-bottom: 30px;
}
