@import "constants/constants.scss";

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 660px;
    border: 1px solid $color_lightGrey;
    padding: 30px;
    background-color: $color_grey;
    border-radius: 10px;
    @media (max-width: 768px) {
        width: 90%;
        padding: 20px;
    }
    @media (max-height: 768px) {
        max-height: 90%;
        overflow-y: scroll;
    }
    .close {
        position: absolute;
        color: $color_antiqueWhite;
        font-size: 2.5rem;
        top: 10px;
        right: 22px;
        cursor: pointer;
    }
    .close:hover {
        color: $color_orange;
        transition: 0.4s;
    }
    .title {
        color: $color_antiqueWhite;
        font-size: 1.25rem;
        margin-bottom: 20px;
        max-width: 85%;
    }
    img {
        width: 100%;
    }
    .description {
        color: $color_lightGrey;
        margin-bottom: 20px;
        font-size: 0.9rem;
    }
    .tagBox {
        display: flex;
        flex-wrap: wrap;
        .tag {
            color: $color_orange;
            font-size: 0.8rem;
            padding: 3px 10px;
            border: 1px solid $color_orange;
            border-radius: 10px;
            margin: 5px 10px 5px 0;
        }
    }
    .linkBox {
        margin-top: 30px;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        font-size: 0.9rem;
        .link {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            svg {
                color: $color_antiqueWhite;
            }
        }
        a {
            color: $color_antiqueWhite;
            text-decoration: none;
            margin: 0 5px 0 8px;
        }
        a:hover {
            color: $color_orange;
            transition: 0.4s;
        }
    }
}
